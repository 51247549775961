import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.scss';
import Link from '../../01_atoms/Link';
import TagText from '../../01_atoms/Tag/TagText';
import TagIcon from '../../01_atoms/Tag/TagIcon';
import { PTItemWithImage } from '../Item/ItemWithImage/prop-types';

import { formattedDate } from '../../../utils/date-formatter.js';
import DefaultPicture from '../../01_atoms/DefaultPicture';

const FeaturedStory = ({ title, nextLink, image, type, tags, timestamp, width = 9 }) => (
  <div className={`col-12 col-md-${width} featured-story ${styles['featured-story']}`}>
    <Link {...nextLink}>
      <a className="wrapper" title={image ? image.alt : ''}>
        <DefaultPicture loading="lazy" {...image} />

        <div className="gradient" />

        <div className="bottom">
          {type && (
            <>
              <TagText label={type} />
              {typeof tags !== 'undefined' &&
                tags.map((tag, i) => (
                  <TagIcon
                    key={`${tag}-${i}` /* eslint-disable-line react/no-array-index-key */}
                    type={tag}
                  />
                ))}
            </>
          )}

          {timestamp && <mark className="date">{formattedDate(timestamp)}</mark>}

          <h3>{title}</h3>
        </div>
      </a>
    </Link>
  </div>
);

FeaturedStory.propTypes = {
  width: PropTypes.number,
  ...PTItemWithImage,
};

export default FeaturedStory;
