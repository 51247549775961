import React from 'react';
import PropTypes from 'prop-types';

import FeaturedStory from '../../../02_molecules/FeaturedStory';
import ItemWithImage from '../../../02_molecules/Item/ItemWithImage';
import { PTItemWithImage } from '../../../02_molecules/Item/ItemWithImage/prop-types';
import {
  behaviorSettingsProps,
  generateClassNameByBehaviorSettings,
} from '../../../../utils/behaviorSettings';

import styles from './index.module.scss';

const BBFeaturedStories = ({
  title = '',
  items,
  className = '',
  behaviorSettings = null,
  uuid = null,
}) => (
  <div
    className={`bb bb-featured-stories ${
      styles['bb-featured-stories']
    } ${className} ${generateClassNameByBehaviorSettings(behaviorSettings)}`}
    id={uuid}
  >
    <div className="container">
      {title && <h2 className="rockitt">{title}</h2>}

      <div className="row">
        {items && !!items.length && <FeaturedStory {...items[0]} />}

        <div className="col-12 col-md-3">
          {items &&
            items.map((item, index) => (
              <React.Fragment key={item.title}>
                {index > 0 && <ItemWithImage {...item} />}
              </React.Fragment>
            ))}
        </div>
      </div>
    </div>
  </div>
);

BBFeaturedStories.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape(PTItemWithImage)).isRequired,
  className: PropTypes.string,
  behaviorSettings: behaviorSettingsProps,
  uuid: PropTypes.string,
};

export default BBFeaturedStories;
